<template>
  <div>
    <TablePro
      v-loading="tableLoading"
      ref="tableProRef"
      hidePagination
      :columns="columns"
      :request="getDataList">
      <template #actionArea>
        <el-button
          type="primary"
          v-permission="['update_shop_brand_auth']"
          @click="onOpenDialog"
          >重新生成</el-button
        >
      </template>
    </TablePro>
    <el-dialog
      :visible.sync="visible"
      width="50%"
      append-to-body
      title="新建授权书"
      @close="onCancel">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="合同模板" prop="templateId">
          <el-select v-model="form.templateId" placeholder="请选择">
            <el-option
              v-for="item in contractType"
              :key="item.code"
              :label="item.content"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="售达方名称" prop="licenseId">
          <el-select v-model="form.licenseId" placeholder="请选择">
            <el-option
              v-for="item in businessLicenses"
              :key="item.id"
              :label="item.businessLicenseName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="门店地址" prop="shopAddress">
          <el-input disabled v-model="form.shopAddress"> </el-input>
        </el-form-item>
        <el-form-item label="代理系列" prop="seriesName">
          <el-select v-model="form.seriesName" placeholder="请选择" multiple>
            <el-option
              v-for="item in series"
              :key="item.code"
              :label="item.name"
              :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="门店编号" prop="shopNumber">
          <el-input v-model="form.shopNumber" disabled></el-input>
        </el-form-item>
        <el-form-item label="授权日期" prop="authorizedDate">
          <el-date-picker
            v-model="form.authorizedDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy年MM月dd日">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="saveLoading" @click="onSave"
            >生成</el-button
          >
          <el-button @click="onCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import TablePro, { render } from '@/components/TablePro'
import {
  requestCertificates,
  getBusinessDataList,
  getShopBrandDataList,
  createBrandAuthorization,
  bestsignCancel
} from '@/api/businessOos'
import { datadictionaryList } from '@/api/common'

import { hasPermission } from '@/utils'
export default {
  name: 'brandAuthorization',
  props: {
    id: {
      type: String,
      default: ''
    },
    rows: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    TablePro
  },
  watch: {},
  data() {
    return {
      saveLoading: false,
      tableLoading: false,
      contractType: [],
      rules: {
        templateId: [
          { required: true, message: '请选择合同模板', trigger: 'change' }
        ],
        licenseId: [
          { required: true, message: '请选择售达方名称', trigger: 'change' }
        ],
        authorizedDate: [
          {
            type: 'array',
            required: true,
            message: '请选择日期',
            trigger: 'change'
          }
        ],
        seriesName: [
          {
            type: 'array',
            required: true,
            message: '请选择系列',
            trigger: 'change'
          }
        ]
      },
      authorizedDate: [],
      form: {
        templateId: '',
        licenseId: '',
        shopAddress: '',
        shopNumber: '',
        seriesName: [],
        authorizedDate: []
      },
      businessLicenses: [],
      series: [],
      visible: false,
      columns: [
        {
          title: '合同编号',
          name: 'contractNumber'
        },
        {
          title: '合同名称',
          name: 'contractName'
        },
        {
          title: '合同照片',
          name: 'employeeNos',
          render: render((h, { data }) => (
            <div>
              <el-button
                type="text"
                onClick={() => {
                  if (!data.domain || !data.relativeUrl) {
                    this.$message.warning('待上上签返回数据，请稍后重试')
                    this.$refs.tableProRef.onRefreshTableList()
                    return
                  }
                  this.previewImg(data.domain + '/' + data.relativeUrl)
                }}>
                查看图片
              </el-button>
              &nbsp;&nbsp;&nbsp;
              <el-button
                type="text"
                onClick={() => {
                  if (!data.domain || !data.relativeUrl) {
                    this.$message.warning('待上上签返回数据，请稍后重试')
                    this.$refs.tableProRef.onRefreshTableList()
                    return
                  }
                  this.downloadImg(
                    data.domain + '/' + data.relativeUrl,
                    data.contractName
                  )
                }}>
                下载图片
              </el-button>
            </div>
          ))
        },
        {
          title: '合同状态',
          name: 'domainCodes',
          render: render((h, { data }) => (
            <div>{data.status === 0 ? '生效' : '失效'}</div>
          ))
        },
        {
          title: '操作',
          render: render((h, { data }) => {
            if (
              data.status === 0 &&
              hasPermission('update_shop_brand_auth') &&
              !data.parentId
            ) {
              return (
                <el-button
                  type="text"
                  size="small"
                  onClick={async () => {
                    try {
                      this.tableLoading = true
                      await bestsignCancel({
                        id: data.id,
                        templateId: data.templateId,
                        licenseId: data.licenseId,
                        shopId: data.shopId
                      })
                      this.$refs.tableProRef.onRefreshTableList()
                      this.$message.success('成功')
                    } catch (error) {
                      throw new Error(error)
                    } finally {
                      this.tableLoading = false
                    }
                  }}>
                  失效
                </el-button>
              )
            }
          })
        }
      ]
    }
  },
  mounted() {
    console.log(this.id)
  },
  methods: {
    onSave() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.saveLoading = true
          try {
            let data = {
              templateId: this.form.templateId,
              licenseId: this.form.licenseId,
              shopId: this.rows.id,
              seriesName: this.form.seriesName.join(','),
              authorizedStartDate: this.form.authorizedDate[0],
              authorizedEndDate: this.form.authorizedDate[1]
            }

            const res = await createBrandAuthorization(data)
            if (res.code != 0) {
              this.$message.error(res.msg)
              return
            }
            this.$message.success('操作成功')
            this.$refs.tableProRef.onRefreshTableList()
            this.visible = false
          } finally {
            this.saveLoading = false
          }
        }
      })
    },
    onCancel() {
      this.$refs.form.resetFields()
      this.visible = false
    },
    async onOpenDialog() {
      const res = await datadictionaryList({
        type: 'accredit',
        module: 'distributor'
      })
      this.contractType = res.data
      const {
        id,
        province = '',
        city = '',
        area = '',
        street = '',
        shopType = '',
        shopTypeValue = '',
        shopFloor = '',
        shopFloorNumber = '',
        shopNumber
      } = this.rows
      const businessLicenseRes = await getBusinessDataList({
        shopId: id,
        shopStatus: 0
      })
      this.businessLicenses = businessLicenseRes.data
      // this.form.licenseName = businessLicenseRes.data
      //   .map(item => item.businessLicenseName)
      //   .join('、')
      const seriesRes = await getShopBrandDataList({ shopId: id, queryAll: 3 })
      this.series = seriesRes.data
      this.form.seriesName = []
      let shopTypeContent = ''
      if (shopType) {
        const res = await datadictionaryList({
          module: 'distributor',
          type: 'store_type',
          code: shopType
        })
        shopTypeContent = res.data[0].content
      }
      this.form.shopAddress = `${province}${city}${area}${street}${shopTypeContent}${shopTypeValue}${shopFloor}${shopFloorNumber}`
      this.form.shopNumber = shopNumber
      this.visible = true
    },
    async getDataList(params) {
      const res = await requestCertificates(this.id)
      return {
        data: res.data
      }
    },
    downloadImg(url, name) {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      })
      // 下载pdf文件
      var list = url.split('/')
      const a = document.createElement('a')
      fetch(url)
        .then(res => res.blob())
        .then(blob => {
          // 将链接地址字符内容转变成blob地址
          a.href = URL.createObjectURL(blob)
          a.download = name || '' // 下载文件的名字
          document.body.appendChild(a)
          a.click()
          loading.close()
          setTimeout(() => {
            a.remove()
          }, 5000)
        })
    },
    previewImg(url) {
      window.open(url)
    },
    handleSend(url) {}
  }
}
</script>

<style scoped lang="scss">
.sort {
  color: #2a60e4;
  cursor: pointer;
  & + .sort {
    margin-right: 10px;
  }
}
</style>
